<template>
  <div>
    <div v-for="item in list" :key="item.permId" class="text item">
      <el-link type="info" class="vg_cursor_hander" @click="jumpList(item.route, item.permId)">{{ item.moudlename }}</el-link>
    </div>
  </div>
</template>

<script>
import { get } from '@api/request';
import { modrAPI } from '@/api/modules/modr';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'RelationList',
  data() {
    return {
      list: [
        { permId: 115, moudlename: '供应商信息', route: '/supp_list' },
        { permId: 121, moudlename: '材料信息', route: '/mtrb_list' },
        { permId: 126, moudlename: '销售合同', route: '/scon_list' },
        { permId: 136, moudlename: '变更记录', route: '/modc_list' }
      ],
      modrForm: {}
    };
  },
  created() {
    this.getSconInfo();
  },
  methods: {
    // 跳转
    jumpList(val, val1) {
      if (val1 === 115) {
        this.jump(val, { perm_id: val1, supp_id: this.modrForm.supp_id });
      } else if (val1 === 121) {
        let temp = [];
        this.modrForm.modr_mtrb_list.forEach(item => {
          temp.push(item.mtrb_id);
        });
        let str = temp.join(',');
        this.jump(val, { perm_id: val1, mtrb_ids: str });
      } else if (val1 === 126) {
        this.jump(val, { perm_id: val1, scon_no: this.modrForm.scon_no });
      } else if (val1 === 136) {
        let str = this.modrForm.modr_no;
        this.jump(val, { perm_id: val1, modr_no: str });
      }
    },
    //获取表单信息
    getSconInfo(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(modrAPI.getModrById, { modr_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.modrForm = res.data.data.form;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
</style>
